@import './variables.breakpoints.module.scss';

.not-found {
  margin: auto;
  max-width: 504px;
  padding: 129px 30px 30px 30px;
  table-layout: fixed;
  width: 100%;

  .heading-text {
    clear: both;
    margin: 18px 0px 0px 0px;
    text-align: center;

    h1 {
      font-size: 243.7px;
      font-style: normal !important;
      font-weight: 700 !important;
      letter-spacing: -0.05em;
      line-height: 0.5;
    }

    h5 {
      font-size: 60.8px;
      font-style: normal !important;
      font-weight: 700 !important;
      letter-spacing: -0.05em;
      line-height: 1;
      margin: var(--spacing-3) 0 !important;
    }
  }

  .empty-space {
    padding-top: 30px;
  }

  .text-lead,
  .text-lead > * {
    font-size: 18px;
    line-height: 1.75;
    margin: 18px 0 0;
    margin: 30px 0px 0px 0px;
  }

  .btn-container {
    display: inline-block;
    margin-top: 0px !important;
    padding-left: 9px;
    padding-right: 9px;
    text-align: center;
    vertical-align: bottom;
    width: 100%;
    .custom-link {
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-text-stroke: 0px;
      -webkit-user-select: none;
      background-color: #ffffff !important;
      background-image: none;
      border-color: #ffffff !important;
      border-radius: 10em !important;
      border-radius: 2px;
      border-style: solid;
      border-width: 1px;
      color: #303133 !important;
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      font-style: normal !important;
      font-weight: 400;
      letter-spacing: 0.1em;
      margin-bottom: 1px;
      margin-top: 1px;
      outline: none;
      padding: 13px 31px 13px 31px !important;
      text-align: center;
      text-transform: uppercase;
      transform-origin: center;
      transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
      border-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
      transition: color 200ms ease-in-out, background-color 200ms ease-in-out, border-color 200ms ease-in-out;
      vertical-align: middle;
      white-space: pre-wrap;
    }
  }

  .divider-wrapper {
    margin-top: 20px;
  }

  hr {
    border-color: rgba(var(--white-rgba), 0.25);
    border-style: solid;
    border-top-width: 1px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
